// from https://stackoverflow.com/questions/67410324/next-js-with-firebase-onauthstatechanged

import { useEffect, useState } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"

import firebase from "../firebase/clientApp"

export function useAuth() {
  const [user, setUser] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const auth = getAuth(firebase)
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setLoading(false)
      } else {
        setUser(null)
        setLoading(false)
      }
    })
  }, [user])

  return { user, loading }
}
