// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5eN7Tpq_W5tNhJsM9F0xQ0Bfe4Boaqg8",
  authDomain: "weight-graph-ad422.firebaseapp.com",
  databaseURL:
    "https://weight-graph-ad422-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "weight-graph-ad422",
  storageBucket: "weight-graph-ad422.appspot.com",
  messagingSenderId: "1029916050259",
  appId: "1:1029916050259:web:56d774357fa192e47417a6",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export default app
