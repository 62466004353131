import { useEffect, useState, useReducer } from "react"

import Head from "next/head"
import Link from "next/link"
import firebase from "../firebase/clientApp"
import {
  getDatabase,
  push,
  ref,
  set,
  query,
  onValue,
  equalTo,
  orderByChild,
} from "firebase/database"
import { getAuth, signOut } from "firebase/auth"
import dayjs from "dayjs"
import { curveBasis } from "d3-shape"
import confetti from "canvas-confetti"
import ReactSlider from "react-slider"

import { useAuth } from "../util/useAuth"

import "react-vis/dist/style.css"

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
} from "react-vis"

const EditModal = ({ closeModal, date, note, submitForm, weight }) => {
  const [modalWeight, setModalWeight] = useState(weight)
  const [modalNote, setModalNote] = useState(note)
  const [modalWeightError, setModalWeightError] = useState("")
  const modalDate = date

  const handleModalChange = (e) => {
    if (e.target.name === "modalWeight") {
      setModalWeightError("")
      setModalWeight(e.target.value)
    }
    if (e.target.name === "modalNote") {
      setModalNote(e.target.value)
    }
  }

  const submitModalForm = () => {
    if (modalWeight && modalWeight.toString().includes(",")) {
      setModalWeightError("Please use . instead of , for decimal numbers")
      return
    }
    if (modalWeight && !modalWeight.toString().match(/^\d+(\.\d+)?$/)) {
      setModalWeightError("Weight is not a valid number")
      return
    }

    submitForm(modalNote, modalWeight)
    closeModal()
  }

  return (
    <div
      style={{
        position: "fixed" /* Stay in place */,
        zIndex: 20 /* Sit on top */,
        left: 0,
        top: 0,
        width: "100%" /* Full width */,
        height: "100%" /* Full height */,
        overflow: "auto" /* Enable scroll if needed */,
        backgroundColor: "white" /* Fallback color */,
      }}
    >
      <span className="float-right mr-3">
        <button
          onClick={() => closeModal()}
          style={{
            fontSize: 28,
            fontWeight: "bold",
          }}
        >
          &times;
        </button>
      </span>

      <div style={{ padding: "0px 20px 0px", fontSize: 16 }}>
        <h1 className="text-3xl my-4">{dayjs(date).format("DD/MM/YYYY")}</h1>

        <div className="mb-2">
          <label htmlFor="modalWeight" className="font-bold">
            Weight:{" "}
          </label>
        </div>
        <input
          name="modalWeight"
          id="modalWeight"
          value={modalWeight}
          onChange={handleModalChange}
          className="shadow appearance-none border rounded mr-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {modalWeightError && (
          <div style={{ color: "red" }}>{modalWeightError}</div>
        )}

        <br />
        <br />

        <div className="mb-2">
          <label htmlFor="modalWeight" className="font-bold">
            Note:{" "}
          </label>
        </div>
        <textarea
          name="modalNote"
          id="modalNote"
          value={modalNote}
          onChange={handleModalChange}
          className="shadow appearance-none border rounded mr-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="p-5">
        <button
          type="submit"
          onClick={submitModalForm}
          className="bg-sky-600 hover:bg-sky-500 text-white font-bold py-2 px-4 rounded w-full"
        >
          Submit
        </button>
      </div>
    </div>
  )
}

const ShareDataModal = ({ closeModal, onSubmit }) => {
  const [userEmail, setUserEmail] = useState("")
  const [userEmailError, setUserEmailError] = useState()

  const changeUserEmail = (event) => {
    event.preventDefault()
    setUserEmailError("")
    setUserEmail(event.target.value)
  }

  const submitModalForm = () => {
    // email validation

    // shouldn't be your own
    // should look like an email
    // should not be blank

    // if (modalWeight && modalWeight.toString().includes(",")) {
    //   setModalWeightError("Please use . instead of , for decimal numbers")
    //   return
    // }
    // if (modalWeight && !modalWeight.toString().match(/^\d+(\.\d+)?$/)) {
    //   setModalWeightError("Weight is not a valid number")
    //   return
    // }

    // set an entry in the DB - DataSharing (user, other user, status: pending|accepted)

    onSubmit(userEmail)
    closeModal()
  }

  return (
    <div
      style={{
        position: "fixed" /* Stay in place */,
        zIndex: 20 /* Sit on top */,
        left: 0,
        top: 0,
        width: "100%" /* Full width */,
        height: "100%" /* Full height */,
        overflow: "auto" /* Enable scroll if needed */,
        backgroundColor: "white" /* Fallback color */,
      }}
    >
      <span className="float-right mr-3">
        <button
          onClick={() => closeModal()}
          style={{
            fontSize: 28,
            fontWeight: "bold",
          }}
        >
          &times;
        </button>
      </span>

      <div style={{ padding: "0px 20px 0px", fontSize: 16 }}>
        <h1 className="text-3xl my-4">Share my data</h1>

        <div className="flex justify-center">
          <form onSubmit={submitModalForm}>
            <div className="py-4">
              <label htmlFor="user">
                Who do you want to share your data with (user email)?
              </label>
            </div>

            <div>
              <input
                className="shadow appearance-none border rounded mr-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="user"
                id="user"
                value={userEmail}
                onChange={changeUserEmail}
              />
              <input
                className="bg-sky-600 hover:bg-sky-500 text-white font-bold py-2 px-4 rounded"
                type="submit"
                value="Submit"
              />
            </div>
            {userEmailError && (
              <div className="py-2 text-red-500">{userEmailError}</div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

function weightsReducer(state, action) {
  switch (action.type) {
    case "add":
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

function initialFirstDateReducer(state, action) {
  switch (action.type) {
    case "update":
      const dates = Object.keys(action.payload)
      const firstDate = new Date(dates[0])
      // console.log(`Checking first date ${firstDate} vs state ${state}`)
      return !state || firstDate < state ? firstDate : state
    default:
      throw new Error()
  }
}

function initialLastDateReducer(state, action) {
  switch (action.type) {
    case "update":
      const dates = Object.keys(action.payload)
      const lastDate = new Date(dates[dates.length - 1])
      // console.log(`Checking last date ${lastDate} vs state ${state}`)
      return !state || lastDate > state ? lastDate : state
    default:
      throw new Error()
  }
}

export default function Home() {
  const authState = useAuth()
  const db = getDatabase()

  const [authUser, setAuthUser] = useState(null)
  const todaysDate = new Date().toISOString().slice(0, 10)
  const [weight, setWeight] = useState("")
  const [weightError, setWeightError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [firstFilterValue, setFirstFilterValue] = useState(0)
  const [lastFilterValue, setLastFilterValue] = useState(100)
  const [showModal, setShowModal] = useState(false)
  const [showShareDataModal, setShowShareDataModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [weightListId, setWeightListId] = useState(null)
  const [sharedWeightListIds, setSharedWeightListIds] = useState([])

  // Weight state management is more complex (ie needs to collect add data first), so needs reducer
  const [weights, dispatchWeights] = useReducer(weightsReducer, {})
  const [initialFirstDate, dispatchInitialFirstDate] = useReducer(
    initialFirstDateReducer
  )
  const [initialLastDate, dispatchInitialLastDate] = useReducer(
    initialLastDateReducer
  )

  // Step 1. Load user
  useEffect(() => {
    !authState.loading && authState.user && setAuthUser(authState.user)
  }, [authState])

  // Step 2. Get weightsList id
  useEffect(() => {
    if (!authUser) return

    console.log("CHECKING WEIGHTLISTS")

    // 2a. Get list id for own list
    const usersWeightList = query(
      ref(db, "weightsToUsersMappings"),
      orderByChild("admin"),
      equalTo(authUser.uid)
    )
    onValue(usersWeightList, (snapshot) => {
      const weightListId = Object.keys(snapshot.val())[0]
      console.log("ID: ", weightListId)
      setWeightListId(weightListId)
    })

    // 2b. Get list ids for shared lists
    const sharedWeightLists = query(
      ref(db, "weightsToUsersMappings"),
      orderByChild(authUser.uid),
      equalTo("true")
    )
    onValue(sharedWeightLists, (snapshot) => {
      if (!snapshot.val()) return

      const usersWeightListIds = Object.keys(snapshot.val())
      console.log({ usersWeightListIds })
      setSharedWeightListIds(usersWeightListIds)
    })
  }, [authUser])

  // Step 3. Fetch own data from weightsList
  useEffect(() => {
    const listIds = [weightListId, ...sharedWeightListIds]

    listIds.forEach((listID) => {
      const weightsRef = ref(db, `weightLists/${listID}`)
      onValue(weightsRef, (snapshot) => {
        const data = snapshot.val()

        if (!data) {
          return
        }

        console.log("SETTING WEIGHTS FOR ID", listID)
        dispatchWeights({ type: "add", payload: { [listID]: data } })
        dispatchInitialFirstDate({ type: "update", payload: data })
        dispatchInitialLastDate({ type: "update", payload: data })
      })
    })
  }, [weightListId, sharedWeightListIds])

  // Step 4. Update the last filter value based on the longest loaded data set
  useEffect(() => {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((initialFirstDate - initialLastDate) / oneDay)
    )

    setLastFilterValue(diffDays + 1)
  }, [initialFirstDate, initialLastDate])

  const changeWeight = (event) => {
    event.preventDefault()
    setWeightError("")
    setSuccessMessage("")
    setWeight(event.target.value)
  }

  const submit = (event) => {
    event.preventDefault()

    // Validation
    if (!weight) {
      setWeightError("Please enter a weight")
      return
    }
    if (weight.includes(",")) {
      setWeightError("Please use . instead of , for decimal numbers")
      return
    }
    if (!weight.match(/^\d+(\.\d+)?$/)) {
      setWeightError("Weight is not a valid number")
      return
    }

    // const todaysValues = values[todaysDate] || {}
    // const key = keysLookup[authUser.email]

    // TODO could make this conditional
    confetti({
      particleCount: 150,
      spread: 70,
      origin: { y: 0.8 },
    })

    // console.log(authState.user)

    const db = getDatabase(firebase)
    set(
      ref(db, `weightLists/${weightListId}/${todaysDate}`),
      {
        weight: weight,
      },
      setSuccessMessage("Great, your weight has been submitted!")
    )
  }

  const createSharingPact = (accepterEmail) => {
    const db = getDatabase(firebase)

    // validate that this email hasn't already been used

    // See `push` -> https://firebase.blog/posts/2014/04/best-practices-arrays-in-firebase
    push(ref(db, `sharingPacts/${authState.user.uid}`), {
      recipient: accepterEmail,
      status: "pending",
    })
  }

  // TODO - extract this somehow?
  const signout = () => {
    const auth = getAuth(firebase)
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("signout")
        setShowMobileMenu(false)
        // router.push("/login")
      })
      .catch((error) => {
        // An error happened.
      })
  }

  const ownWeights = weights && weights[weightListId]

  const dates = [
    ...new Set(
      Object.entries(weights)
        .map((list) => Object.keys(list[1]))
        .flat()
        .sort()
    ),
  ]

  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds

  const firstDate = new Date(dates[0])
  const lastDate = new Date(dates[dates.length - 1])

  const diffDays = Math.round(Math.abs((firstDate - lastDate) / oneDay)) || 100

  // Map dates and weights to x and y coords for the graph
  let data = {}
  Object.entries(weights).forEach(([listId, listWeights] = entry) => {
    const listData = Object.entries(listWeights)
      .filter(([key, value]) => {
        const date = new Date(key)

        const isDefined = value.weight
        const isAfterFirstDate =
          date.getTime() >= firstDate.getTime() + oneDay * firstFilterValue
        const isBeforeLastDate =
          date.getTime() < firstDate.getTime() + oneDay * lastFilterValue

        return isAfterFirstDate && isBeforeLastDate && isDefined
      })
      .map((entry) => {
        return { x: new Date(entry[0]).getTime(), y: entry[1].weight }
      })

    data[listId] = listData
  })

  // TODO - Create some dummy data if only 1 value is provided so users can see the graph at least
  // let dummyData = []
  // if (data.length == 1) {
  //   dummyData.push({ x: data[0].x, y: data[0].y })
  //   dummyData.push({ x: data[0].x - oneDay, y: data[0].y })
  // }

  // Calculate display dates for the slider
  let firstDisplayDateRaw = null
  let lastDisplayDateRaw = null

  Object.entries(data).forEach(([_key, values] = entry) => {
    const firstDate = values[0]?.x
    const lastDate = values[values.length - 1]?.x

    if (!firstDisplayDateRaw || firstDate < firstDisplayDateRaw) {
      firstDisplayDateRaw = firstDate
    }
    if (!lastDisplayDateRaw || lastDate > lastDisplayDateRaw) {
      lastDisplayDateRaw = lastDate
    }
  })

  const firstDisplayDate =
    firstDisplayDateRaw &&
    dayjs(new Date(firstDisplayDateRaw)).format("DD/MM/YYYY")
  const lastDisplayDate =
    lastDisplayDateRaw &&
    dayjs(new Date(lastDisplayDateRaw)).format("DD/MM/YYYY")

  // TODO - this needs to be dynamic
  // Pick better default colours...
  const userMappings = {
    "89103a31-084a-4a84-b5a9-361a7c50bd5c": {
      color: "red", // dynamic
      name: "David Basalla", // fetched from DB
    },
    "d28177de-7ef8-4fec-b764-89f95b6d4599": {
      color: "blue",
      name: "Ana Paula Bartmann",
    },
  }

  return (
    <div>
      <Head>
        <title>Weight Graph</title>
        <meta name="description" content="App to display weight" />
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎈</text></svg>"
        />
      </Head>

      {showModal && (
        <EditModal
          closeModal={() => setShowModal(false)}
          date={selectedDate}
          note={ownWeights[selectedDate].note}
          submitForm={(note, weight) => {
            const db = getDatabase(firebase)
            set(ref(db, `weightLists/${weightListId}/${selectedDate}`), {
              weight: weight || "",
              note: note || "",
            })
          }}
          weight={ownWeights[selectedDate].weight}
        />
      )}

      {showShareDataModal && (
        <ShareDataModal
          closeModal={() => setShowShareDataModal(false)}
          onSubmit={createSharingPact}
        />
      )}

      <main>
        <nav className="flex justify-between bg-teal-600 p-2 shadow-lg">
          <h1 className="text-2xl text-white">🎈 Weight Graph</h1>

          {!authState.loading && authState.user && (
            <>
              <span className="text-white mr-2 hidden md:block">
                {authState.user.email} |{" "}
                <button
                  disabled
                  className="underline"
                  onClick={() => setShowShareDataModal(true)}
                >
                  Share my data
                </button>{" "}
                |{" "}
                <button className="underline" onClick={signout}>
                  Sign out
                </button>
              </span>
              <div className="md:hidden flex items-center">
                <button
                  className="outline-none mobile-menu-button"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <svg
                    className="w-6 h-6 text-white"
                    x-show="!showMenu"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                </button>
              </div>{" "}
            </>
          )}
        </nav>
        {!authState.loading && authState.user && showMobileMenu && (
          <ul className="bg-teal-500 p-2 text-white shadow-lg">
            <li>{authState.user.email}</li>
            <li>
              <button
                disabled
                className="underline"
                onClick={() => setShowShareDataModal(true)}
              >
                Share my data
              </button>{" "}
            </li>
            <li>
              <button className="underline" onClick={signout}>
                Sign out
              </button>
            </li>
          </ul>
        )}

        <p />

        {authState.loading && "Loading..."}
        {!authState.loading && !authState.user && (
          <div className="m-4 text-center underline">
            <Link href="/signup">
              <a>Log in</a>
            </Link>
          </div>
        )}
        {!authState.loading && authState.user && (
          <>
            <div className="flex justify-center">
              {/* Weight input */}
              <form onSubmit={submit}>
                <div className="py-4">
                  <label htmlFor="weight">
                    {`What is your weight today (${dayjs(todaysDate).format(
                      "DD/MM/YYYY"
                    )})? `}
                  </label>
                </div>

                <div>
                  <input
                    className="shadow appearance-none border rounded mr-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="weight"
                    id="weight"
                    value={weight}
                    onChange={changeWeight}
                  />
                  <input
                    className="bg-sky-600 hover:bg-sky-500 text-white font-bold py-2 px-4 rounded"
                    type="submit"
                    value="Submit"
                  />
                </div>
                {weightError && (
                  <div className="py-2 text-red-500">{weightError}</div>
                )}
                {successMessage && (
                  <div className="py-2 text-teal-600">{successMessage}</div>
                )}
              </form>
            </div>

            <br />

            {/* Graph */}
            {/* Need to add something for data && data.length == 1 && */}
            {/* TODO - refactor this to clean it up */}

            <div className="mx-4 mb-2 shadow-md border rounded">
              <FlexibleWidthXYPlot
                xType="time"
                height={300}
                margin={{ bottom: 70 }}
              >
                <HorizontalGridLines />
                <VerticalGridLines />
                <XAxis title="Time" tickLabelAngle={-45} />
                <YAxis title="Weight (kg)" />
                {Object.entries(data).map(([listId, values] = entry) => {
                  return (
                    <LineSeries
                      color={userMappings[listId]?.color}
                      key={listId}
                      curve={curveBasis}
                      data={values}
                    />
                  )
                })}
              </FlexibleWidthXYPlot>
              <div className="flex mx-4">
                {Object.entries(userMappings).map(
                  ([listId, userValues] = entry) => {
                    return (
                      <div key={listId} className="mr-4">
                        <div
                          style={{
                            float: "left",
                            height: "14px",
                            width: "14px",
                            marginTop: "5px",
                            marginBottom: "15px",
                            marginRight: "5px",
                            border: "1px solid black",
                            clear: "both",
                            backgroundColor: userValues.color,
                          }}
                        ></div>
                        {userValues.name}
                      </div>
                    )
                  }
                )}
              </div>
            </div>

            {/* Range slider */}
            {dates.length > 2 && (
              <div className="mx-4 mb-2 pt-4 border rounded text-gray-700 shadow-md">
                <div className="flex justify-between mx-4">
                  <div className="font-medium">{firstDisplayDate}</div>
                  <div className="font-medium">{lastDisplayDate}</div>
                </div>

                {/* https://stackoverflow.com/questions/57967579/input-range-slider-as-react-hook-does-not-slide */}
                <ReactSlider
                  step={1}
                  min={0}
                  max={diffDays + 1}
                  pearling
                  minDistance={2}
                  className="h-5 m-4 mb-8 bg-gray-200 rounded-md cursor-grab"
                  thumbClassName="absolute w-5 h-5 cursor-grab bg-sky-600 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600 -top-2px"
                  value={[firstFilterValue, lastFilterValue]}
                  onChange={([first, last]) => {
                    setFirstFilterValue(first)
                    setLastFilterValue(last)
                  }}
                />
              </div>
            )}

            {/* Table */}
            <div className="mx-4 shadow-md border rounded p-2">
              <table>
                <thead>
                  <tr>
                    <th className="border p-2">Date</th>
                    <th className="border p-2">
                      {/* {authState.user.email} */}
                      Weight
                    </th>
                    <th className="border p-2">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {ownWeights &&
                    Object.entries(ownWeights)
                      .reverse()
                      .map(([date, values] = entry) => {
                        return (
                          <tr key={date}>
                            <td
                              className="border p-2"
                              onClick={() => {
                                setSelectedDate(date)
                                setShowModal(true)
                              }}
                            >
                              {dayjs(date).format("DD/MM/YYYY")}
                            </td>
                            <td
                              className="border p-2"
                              onClick={() => {
                                setSelectedDate(date)
                                setShowModal(true)
                              }}
                            >
                              {values.weight}
                            </td>
                            <td
                              className="border p-2"
                              onClick={() => {
                                setSelectedDate(date)
                                setShowModal(true)
                              }}
                            >
                              {values.note}
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>

              {weights && weights.length == 0 && (
                <div className="p-2">
                  <p>No weight to display yet...</p>
                </div>
              )}
            </div>

            <br />
          </>
        )}
      </main>
    </div>
  )
}
